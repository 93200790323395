/* eslint-disable react/no-danger */
import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import { Doughnut, Bar } from 'react-chartjs-2';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Observer from 'react-intersection-observer';
import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-deferred';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import EditIcon from '../../static/assets/edit.svg';
import * as globalAnimation from '../animations/globalAnimation';
// import * as Therapeutic from '../animations/Therapeutic';

const data = {
  labels: [
    'Oncology',
    'Neuroscience',
    'CV & Metabolics',
    'Anti-Infectives & Critical Care',
    'Orphan Diseases',
    'Immunology',
    'Women`s Health',
    'Others',
  ],
  // borderWidth: '3px',
  datasets: [
    {
      label: 'Projects Done in 2018',
      backgroundColor: [
        '#0A4169',
        '#57b7f9',
        '#f4ba01',
        '#C32A1F',
        '#800000',
        '#77933C',
        '#005A2A',
        '#062740',
      ],
      borderColor: 'rgba(0,10,220,0)',
      data: [101, 39, 20, 28, 32, 20, 10, 22],
      hoverBackgroundColor: [
        '#0A4169',
        '#57b7f9',
        '#f4ba01',
        '#C32A1F',
        '#800000',
        '#77933C',
        '#005A2A',
        '#062740',
      ],
    },
  ],
};

const barData = {
  datasets: [
    {
      label: 'Number of Engagements in Oncology',
      borderWidth: 0,
      data: [20, 25, 45, 60, 78, 84, 88, 102, 126],
      hoverBackgroundColor: '#57b7f9',
      hoverBorderColor: '#57b7f9',
      backgroundColor: '#57b7f9',
    },
  ],
  labels: ['2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019'],
};

export default class TherapeuticExperience extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    globalAnimation.fadeUp(this.tl, '.animate-mosaic-up');
    globalAnimation.fadeUp(this.tl, '.animate-text-up');
    // Therapeutic.animateMosacards(this.tl, '.masaCard', '#desc');
  }

  projectBreadthAnimation = InView => {
    const tlProjectBreath = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.animateHeading(tlProjectBreath, '.animate-title', '.animate-subtitle');
    }
  };

  animateMosaDescription = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.animateHeading(tl, '#mosa-description');
      globalAnimation.animateProjectTile(tl, '.project_container');
    }
  };

  render() {
    const {
      data: {
        markdownRemark: {
          html,
          frontmatter: {
            title,
            description,
            mosaCards: { one, two, three, four, five, six, seven, eight },
            mosaDescription,
            projectBreadth,
            projectBreadth: {
              completedProject,
              completedProject: { projects },
            },
            barChart,
          },
        },
      },
    } = this.props;

    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Therapeutic Experience" />
        <Container fluid="true">
          <Row>
            <Col xs={12} lg={12}>
              <div className="main_wrapper">
                <Row>
                  <Col>
                    <div className="wrapper">
                      <div className="page_heading_section text-center">
                        <h3 className="page_heading text-uppercase d-lg-block d-none title">
                          {title}
                        </h3>
                        <h4 className="text-uppercase page_heading_child subtitle">{title}</h4>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="">
                <Container>
                  <Row>
                    <Col>
                      <div className="animate-text-up">
                        <div className="px-lg-5">
                          <p id="desc" className="text-semibold text-center">
                            {description}
                          </p>
                        </div>
                      </div>
                    </Col>

                    <Col xs={12}>
                      <Row noGutters className="therapeutic_masonry animate-mosaic-up">
                        <Col xs={12} lg={3}>
                          <div className="brick mx-2">
                            <Img className="img-fluid" fluid={one.image.childImageSharp.fluid} />
                            <div className="front_text d-flex justify-content-center align-items-center">
                              <h5 className="text-white text-center">{one.title}</h5>
                            </div>

                            <div className="description_block text-center p-3">
                              <div className="d-flex justify-content-center flex-column">
                                <h5 className="text-bold text-appcolor">{one.title}</h5>
                                <div className="bottom_line card-bottom-line mx-auto my-1" />
                                <p className="">{one.description}</p>
                              </div>
                            </div>
                          </div>
                          <div className="brick mx-2">
                            <Img className="img-fluid" fluid={two.image.childImageSharp.fluid} />
                            <div className="front_text d-flex justify-content-center align-items-center">
                              <h5 className="text-white text-center">{two.title}</h5>
                            </div>
                            <div className="description_block text-center p-3">
                              <h5 className="text-bold text-appcolor">{two.title}</h5>
                              <div className="bottom_line card-bottom-line mx-auto my-1" />
                              <p>{two.description}</p>
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} lg={3}>
                          <div className="brick mx-2">
                            <Img className="img-fluid" fluid={three.image.childImageSharp.fluid} />
                            <div className="front_text d-flex justify-content-center align-items-center">
                              <h5 className="text-white text-center">{three.title}</h5>
                            </div>
                            <div className="description_block text-center p-3">
                              <h5 className="text-bold text-appcolor">{three.title}</h5>
                              <div className="bottom_line card-bottom-line mx-auto my-1" />
                              <p className="mb-0">{three.description}</p>
                            </div>
                          </div>
                          <div className="brick mx-2">
                            <Img className="img-fluid" fluid={four.image.childImageSharp.fluid} />
                            <div className="front_text d-flex justify-content-center align-items-center">
                              <h5 className="text-white text-center">{four.title}</h5>
                            </div>
                            <div className="description_block text-center p-3">
                              <h5 className="text-bold text-appcolor">{four.title}</h5>
                              <div className="bottom_line card-bottom-line mx-auto my-1" />
                              <p>{four.description}</p>
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} lg={3}>
                          <div className="brick mx-2">
                            <Img className="img-fluid" fluid={five.image.childImageSharp.fluid} />
                            <div className="front_text d-flex justify-content-center align-items-center">
                              <h5 className="text-white text-center">{five.title}</h5>
                            </div>
                            <div className="description_block text-center p-3">
                              <h5 className="text-bold text-appcolor">{five.title}</h5>
                              <div className="bottom_line card-bottom-line mx-auto my-1" />
                              <p>{five.description}</p>
                            </div>
                          </div>
                          <div className="brick mx-2">
                            <Img className="img-fluid" fluid={six.image.childImageSharp.fluid} />
                            <div className="front_text d-flex justify-content-center align-items-center">
                              <h5 className="text-white text-center">{six.title}</h5>
                            </div>
                            <div className="description_block text-center p-3">
                              <h5 className="text-bold text-appcolor">{six.title}</h5>
                              <div className="bottom_line card-bottom-line mx-auto my-1" />
                              <p>{six.description}</p>
                            </div>
                          </div>
                        </Col>
                        <Col xs={12} lg={3}>
                          <div className="brick mx-2">
                            <Img className="img-fluid" fluid={seven.image.childImageSharp.fluid} />
                            <div className="front_text d-flex justify-content-center align-items-center">
                              <h5 className="text-white text-center">{seven.title}</h5>
                            </div>
                            <div className="description_block text-center p-3">
                              <h5 className="text-bold text-appcolor">{seven.title}</h5>
                              <div className="bottom_line card-bottom-line mx-auto my-1" />
                              <p>{seven.description}</p>
                            </div>
                          </div>
                          <div className="brick mx-2">
                            <Img className="img-fluid" fluid={eight.image.childImageSharp.fluid} />
                            <div className="front_text d-flex justify-content-center align-items-center">
                              <h5 className="text-white text-center">{eight.title}</h5>
                            </div>
                            <div className="description_block text-center p-3">
                              <h5 className="text-bold text-appcolor">{eight.title}</h5>
                              <div className="bottom_line card-bottom-line mx-auto my-1" />
                              <p>{eight.description}</p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <Observer
                        onChange={this.animateMosaDescription}
                        threshold="0.5"
                        rootMargin="0px"
                        triggerOnce
                      >
                        <div className="py-lg-5 py-2 px-lg-5">
                          <p id="desc">{mosaDescription}</p>
                          <div className="text-center text-semibold">
                            <div id="mosa-description" dangerouslySetInnerHTML={{ __html: html }} />
                          </div>
                        </div>
                      </Observer>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
            <Col xs={12}>
              <Row className="bg-grey">
                <Col>
                  <div className="center">
                    <section>
                      <Row>
                        <Col>
                          <div className="wrapper">
                            <Observer onChange={this.projectBreadthAnimation} triggerOnce>
                              <div className="page_heading_section text-center">
                                <h3 className="page_heading text-uppercase d-lg-block d-none animate-title">
                                  {projectBreadth.title}
                                </h3>
                                <h4 className="text-uppercase page_heading_child animate-subtitle ">
                                  {projectBreadth.title}
                                </h4>
                              </div>
                            </Observer>
                          </div>
                        </Col>
                      </Row>
                      <div className="wrapper pt-0">
                        <Row>
                          <Col xs={12} sm={12} md={5} lg={5}>
                            <div
                              id="canvas-holder"
                              className="position-relative canvas_holder d-flex align-items-center py-lg-5"
                            >
                              <Doughnut
                                id="chart-area"
                                data={data}
                                height={400}
                                width={600}
                                options={{
                                  //   segmentShowStroke: true,
                                  //   elements: {
                                  //     arc: {
                                  //         border: 2,
                                  //     }
                                  // },
                                  legend: {
                                    display: false,
                                  },
                                  cutoutPercentage: 70,
                                  tooltips: {
                                    enabled: true,
                                  },
                                  responsive: true,
                                  plugins: {
                                    datalabels: {
                                      display: false,
                                    },
                                  },
                                }}
                              />
                            </div>

                            <div className="px-lg-5 py-4 d-none d-md-block">
                              <div className="d-flex h-100 align-items-end">
                                <div className="px-4">
                                  <EditIcon width={25} height={25} />
                                  <p className=" text-muted">{completedProject.description}</p>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} sm={12} lg={7}>
                            <h4 className="text-appcolor text-bold py-4">
                              {completedProject.title}
                            </h4>

                            <Row noGutters>
                              {projects.map((project, index) => (
                                <Col xs={6} sm={6} md={6} lg={6} key={String(index)}>
                                  <div className="project_container w-100 h-100 d-block">
                                    <div
                                      className={`data_container ${project.class} px-3 position-relative`}
                                    >
                                      <p className="text-muted">{project.title}</p>
                                      <h3>{project.number}</h3>
                                    </div>
                                  </div>
                                </Col>
                              ))}

                              <div className="px-lg-5 py-4 d-md-none">
                                <div className="d-flex h-100 align-items-end">
                                  <div className="px-4">
                                    <EditIcon width={25} height={25} />
                                    <p className="text-medium text-muted">
                                      {completedProject.description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="my-5">
                              <h4 className="text-appcolor text-bold">{barChart.title}</h4>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} lg={8}>
                            <div className="h-100 align-items-end d-none d-lg-block">
                              <div
                                id="bar-canvas-holder"
                                className="text-center position-relative w-100 bar_canvas_holder"
                              >
                                <Bar
                                  id="barchart-area"
                                  data={barData}
                                  height={150}
                                  width={300}
                                  options={{
                                    legend: {
                                      display: false,
                                    },
                                    tooltips: {
                                      enabled: false,
                                    },
                                    responsive: true,
                                    scales: {
                                      xAxes: [
                                        {
                                          ticks: {
                                            fontSize: 20,
                                          },
                                          gridLines: {
                                            color: '#0a4169',
                                            display: false,
                                          },
                                          barPercentage: 1,
                                        },
                                      ],
                                      yAxes: [
                                        {
                                          ticks: {
                                            beginAtZero: true,
                                            display: false,
                                          },
                                          gridLines: {
                                            color: '#0a4169',
                                            display: false,
                                          },
                                        },
                                      ],
                                    },
                                    plugins: {
                                      datalabels: {
                                        display: true,
                                        color: '#0a4169',
                                        font: {
                                          weight: 'bold',
                                          color: '#0a4169',
                                          size: 20,
                                        },
                                        align: 'bottom',
                                        anchor: 'end',
                                        offset: -30,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            <div className="h-100 align-items-end d-md-block d-lg-none">
                              <div
                                id="bar-canvas-holder"
                                className="text-center position-relative w-100 bar_canvas_holder"
                              >
                                <Bar
                                  id="barchart-area"
                                  data={barData}
                                  height={350}
                                  width={300}
                                  options={{
                                    legend: {
                                      display: false,
                                    },
                                    tooltips: {
                                      enabled: false,
                                    },
                                    responsive: true,
                                    scales: {
                                      xAxes: [
                                        {
                                          ticks: {
                                            fontSize: 20,
                                          },
                                          gridLines: {
                                            color: '#0a4169',
                                            display: false,
                                          },
                                          barPercentage: 1,
                                        },
                                      ],
                                      yAxes: [
                                        {
                                          ticks: {
                                            beginAtZero: true,
                                            display: false,
                                          },
                                          gridLines: {
                                            color: '#0a4169',
                                            display: false,
                                          },
                                        },
                                      ],
                                    },
                                    plugins: {
                                      datalabels: {
                                        display: true,
                                        color: '#0a4169',
                                        font: {
                                          weight: 'bold',
                                          color: '#0a4169',
                                          size: 20,
                                        },
                                        align: 'bottom',
                                        anchor: 'end',
                                        offset: -30,
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} lg={4} className="h-100 align-self-end mb-lg-3">
                            <div>
                              <div className="px-0 px-lg-3 pt-2">
                                <EditIcon width={25} height={25} />
                                <p className="text-muted mb-0">{barChart.description}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </section>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "therapeutic_experience" } }) {
      html
      frontmatter {
        title
        description
        mosaCards {
          one {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          two {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          three {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          four {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          five {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          six {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          seven {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          eight {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        projectBreadth {
          title
          completedProject {
            title
            description
            projects {
              title
              number
              class
            }
          }
        }
        barChart {
          title
          description
        }
      }
    }
  }
`;
